// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": function componentNodeModulesGatsbyPluginOfflineAppShellJs() {
    return import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js"
    /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */
    );
  },
  "component---src-templates-project-jsx": function componentSrcTemplatesProjectJsx() {
    return import("/opt/build/repo/src/templates/project.jsx"
    /* webpackChunkName: "component---src-templates-project-jsx" */
    );
  },
  "component---src-templates-single-jsx": function componentSrcTemplatesSingleJsx() {
    return import("/opt/build/repo/src/templates/single.jsx"
    /* webpackChunkName: "component---src-templates-single-jsx" */
    );
  },
  "component---src-pages-404-jsx": function componentSrcPages404Jsx() {
    return import("/opt/build/repo/src/pages/404.jsx"
    /* webpackChunkName: "component---src-pages-404-jsx" */
    );
  },
  "component---src-pages-index-jsx": function componentSrcPagesIndexJsx() {
    return import("/opt/build/repo/src/pages/index.jsx"
    /* webpackChunkName: "component---src-pages-index-jsx" */
    );
  }
};